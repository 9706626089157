.SessionSearchField_searchField_3hiHjIq {
  -webkit-box-shadow: none !important;
          box-shadow: none !important
}

.SessionSearchField_searchField_3hiHjIq input {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  border-radius: 3 !important;
  border: solid thin #ddd !important;
  height: 34px !important;
  font-size: 16px;
}