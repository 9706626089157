.SessionListModal_wrapper_1cThQeM {
  padding: 20px;
  background-color: #f6f6f6;
  min-height: calc(100vh - 59px);
}

.SessionListModal_dropdown_t7zZbtp {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  padding: 4px 6px;
  border-radius: 3px;
  color: #333;
  font-weight: 500
}

.SessionListModal_dropdown_t7zZbtp:hover {
  background-color: #ddd;
}

.SessionListModal_dropdownTrigger_1B2J-ig {
  padding: 4px 8px;
  border-radius: 3px
}

.SessionListModal_dropdownTrigger_1B2J-ig:hover {
  background-color: #ddd;
}

.SessionListModal_dropdownIcon_1Tssm07 {
  margin-top: 2px;
  margin-left: 3px;
}