.playerBlockHeader_header_1d_u7IO {
  height: 50px;
  border-bottom: solid thin #ddd;
  padding: 0px 15px;
  background-color: white;
}

.playerBlockHeader_divider_3cW-h6V {
  width: 1px;
  height: 49px;
  margin: 0 15px;
  background-color: #ddd;
}

.playerBlockHeader_liveSwitchButton_n2V1G8A {
  cursor: pointer;
  color: #42AE5E;
  text-decoration: underline;
}