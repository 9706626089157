.attributeItem_wrapper_5ilfr1n {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 8px 15px;
  background-color: white;
  border-bottom: solid thin #f6f6f6
}

.attributeItem_wrapper_5ilfr1n:last-child {
  border-bottom: solid thin transparent;
}

.attributeItem_wrapper_5ilfr1n:hover {
  background-color: #F6F7FF
}

.attributeItem_wrapper_5ilfr1n:hover .attributeItem_actions_11-X1TR {
  opacity: 1;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.attributeItem_wrapper_5ilfr1n > div:not(:last-child) {
  margin-right: 10px;
}

.attributeItem_wrapper_5ilfr1n .attributeItem_label_uhLy2ln {
  font-weight: 600;
  min-width: 80px;
}

.attributeItem_wrapper_5ilfr1n .attributeItem_filterDropdown_3vgv7lT {
  padding: 0 5px !important;
  min-height: 28px !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
  font-weight: 400;
  min-width: 280px !important;
  max-width: 75% !important;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 1.9px !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
  padding-left: 5px !important
}

.attributeItem_wrapper_5ilfr1n .attributeItem_filterDropdown_3vgv7lT a {
  background-color: #f6f6f6 !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  border-radius: 10px !important;
  white-space: nowrap !important;
  margin: 0 !important;
  margin-right: 5px !important;
  margin-bottom: 2px !important;
  font-size: 13px !important;
  font-weight: 400;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
  padding: 3px 5px !important
}

.attributeItem_wrapper_5ilfr1n .attributeItem_filterDropdown_3vgv7lT a i::before  {
  display: none;
}

.attributeItem_wrapper_5ilfr1n .attributeItem_filterDropdown_3vgv7lT a i::after  {
  content: '' !important;
  width: 12px;
  height: 12px;
  display: block;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' class='bi bi-x-lg' viewBox='0 0 16 16' fill='%23666'%3E %3Cpath fill-rule='evenodd' d='M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z'/%3E %3Cpath fill-rule='evenodd' d='M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z'/%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.attributeItem_wrapper_5ilfr1n .attributeItem_filterDropdown_3vgv7lT input {
  padding: 6px !important;
  margin: 0 !important;
  color: #888 !important;
}

.attributeItem_wrapper_5ilfr1n .attributeItem_filterDropdown_3vgv7lT .attributeItem_delete_172hs2y.attributeItem_icon_24Mthb6 {
  padding: 0 !important;
  display: none;
}

.attributeItem_operatorDropdown_6-_u_2Y {
  font-weight: 400;
  height: 28px;
  min-width: 60px;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 8px !important;
  font-size: 13px;
  background-color: rgba(255, 255, 255, 0.8) !important;
  border: solid thin rgba(34, 36, 38, 0.15) !important;
  border-radius: 4px !important;
  color: #333 !important;
  font-size: 14px !important
}

.attributeItem_operatorDropdown_6-_u_2Y.attributeItem_ui_3dCv8S5.attributeItem_basic_2Xhx-K-.attributeItem_button_1vNgzs8 {
  -webkit-box-shadow: 0 0 0 1px rgba(62, 170, 175,36,38,.35) inset, 0 0 0 0 rgba(62, 170, 175,.15) inset !important;
          box-shadow: 0 0 0 1px rgba(62, 170, 175,36,38,.35) inset, 0 0 0 0 rgba(62, 170, 175,.15) inset !important;
}

.attributeItem_button_1vNgzs8 {
  width: 25px;
  height: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
  margin-left: 10px;
}

.attributeItem_actions_11-X1TR {
  margin-left: auto;
  opacity: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.attributeItem_inputValue_sNaJfcS {
  height: 28px !important;
  width: 180px;
  color: #888 !important;
}

.attributeItem_header_sGruqHE {
  margin-bottom: 10px;
  font-size: 13px;
  color: #596764;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 0.1em;
  text-align: left;
}