.autoComplete_menu_3rCfDnS {
  border-radius: 0 0 3px 3px;
  -webkit-box-shadow: 0 2px 10px 0 #ddd;
          box-shadow: 0 2px 10px 0 #ddd;
  padding: 20px;
  background-color: white;
  max-height: 350px;
  overflow-y: auto;
  position: absolute;
  top: 28px;
  left: 0;
  width: 500px;
  z-index: 99;
}

.autoComplete_searchInput_16toz_T input {
  font-size: 13px !important;
  padding: 5px !important;
  color: #333 !important;
  font-size: 14px !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.autoComplete_searchInput_16toz_T {
  height: 28px !important;
  width: 280px;
  color: #333 !important;
}

.autoComplete_fullWidth_3h9sQhI {
  width: 100% !important;
}

.autoComplete_inputWrapper_5KqU_fC {
  border: solid thin #ddd !important;
  border-radius: 3px;
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center
}

.autoComplete_inputWrapper_5KqU_fC input {
  height: 28px;
  font-size: 13px !important;
  padding: 0 5px !important;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.autoComplete_inputWrapper_5KqU_fC .autoComplete_right_17O4w7e {
  height: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 5px;
  background-color: #f6f6f6;
  border-left: solid thin #ddd !important;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  cursor: pointer;
}