.filterDropdown_btn_pKhqvhi {
  height: 29px;
  padding: 0 10px
}

.filterDropdown_btn_pKhqvhi:hover {
  background-color: #ddd;
  color: white
}

.filterDropdown_btn_pKhqvhi:hover svg {
  fill: white;
}

.filterDropdown_filterItem_3RJLZWM:hover {
  background-color: #ddd;
}