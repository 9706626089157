.SavedSearchDropdown_wrapper_SQR6DOJ {
  position: relative;
  display: inline-block;
  z-index: 999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-height: 250px;
  overflow-y: auto;
}

.SavedSearchDropdown_rowItem_2rnxIkS:hover {
  color: #394EFF;
}