.sharePopup_popup_1vvkFwt {
  border-radius: 3px;
  -webkit-box-shadow: 0px 1px 3px 0 #ddd;
          box-shadow: 0px 1px 3px 0 #ddd;
}

.sharePopup_wrapper_RET9z1J {
  background-color: white;
  width: 350px;
}

.sharePopup_header_D1QVb6J {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 10px;
}

.sharePopup_title_1ULTmPg {
  margin-right: auto;
  font-weight: bold;
}

.sharePopup_closeButton_3uGWVvc {
  width: ;
  height: ;
  display: block;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' class='bi bi-x-lg' viewBox='0 0 16 16' fill='%23ddd'%3E %3Cpath fill-rule='evenodd' d='M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z'/%3E %3Cpath fill-rule='evenodd' d='M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z'/%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.sharePopup_body_2-88e39 textarea {
  border: solid thin #ddd;
  border-radius: 3px;
  resize: none;
}

.sharePopup_body_2-88e39 {
  margin-bottom: 14px;
}

.sharePopup_footer_1vThepo {
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: space-between; */
  /* padding: 10px 0; */
  border-top: solid thin #ddd;
  margin: 0 -14px;
  padding: 0 14px;
  /* border-bottom: solid thin $gray-light; */
}

textarea {
  width: 100%;
  background-color: #f6f6f6;
  border: none;
  padding: 10px;
}

.sharePopup_shreButtons_1a9UiLr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: auto;
}

.sharePopup_icon_2_r0XlZ {
  margin: 0 8px
}

.sharePopup_icon_2_r0XlZ:first-child {
  margin-left: 0;
}

.sharePopup_slack_bEtxu0r {
  width: ;
  height: ;
  display: block;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg aria-labelledby='simpleicons-slack-icon' role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' fill='%23394EFF'%3E%3Ctitle id='simpleicons-slack-icon'%3ESlack icon%3C/title%3E%3Cpath d='M9.879 10.995l1.035 3.085 3.205-1.074-1.035-3.074-3.205 1.08v-.017z'/%3E%3Cpath d='M18.824 14.055l-1.555.521.54 1.61c.218.65-.135 1.355-.786 1.574-.15.045-.285.067-.435.063-.511-.015-.976-.338-1.155-.849l-.54-1.607-3.21 1.073.539 1.608c.211.652-.135 1.358-.794 1.575-.15.048-.285.067-.435.064-.51-.015-.976-.34-1.156-.85l-.539-1.619-1.561.524c-.15.045-.285.061-.435.061-.511-.016-.976-.345-1.155-.855-.225-.66.135-1.364.78-1.575l1.56-.525L7.5 11.76l-1.551.525c-.141.048-.285.066-.428.064-.495-.016-.975-.338-1.141-.848-.209-.65.135-1.354.796-1.574l1.56-.52-.54-1.605c-.21-.654.136-1.359.796-1.575.659-.22 1.363.136 1.574.783l.539 1.608L12.3 7.544l-.54-1.605c-.209-.645.135-1.35.789-1.574.652-.211 1.359.135 1.575.791l.54 1.621 1.555-.51c.651-.219 1.356.135 1.575.779.218.654-.135 1.359-.784 1.575l-1.557.524 1.035 3.086 1.551-.516c.652-.211 1.358.135 1.575.795.22.66-.135 1.365-.779 1.574l-.011-.029zm4.171-5.356C20.52.456 16.946-1.471 8.699 1.005.456 3.479-1.471 7.051 1.005 15.301c2.475 8.245 6.046 10.17 14.296 7.694 8.245-2.475 10.17-6.046 7.694-14.296z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 30px;
  height: 30px;
}

.sharePopup_trello_3OVpWTM {
  width: ;
  height: ;
  display: block;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg aria-labelledby='simpleicons-trello-icon' role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' fill='%23394EFF'%3E%3Ctitle id='simpleicons-trello-icon'%3ETrello icon%3C/title%3E%3Cpath d='M21 0H3C1.343 0 0 1.343 0 3v18c0 1.656 1.343 3 3 3h18c1.656 0 3-1.344 3-3V3c0-1.657-1.344-3-3-3zM10.44 18.18c0 .795-.645 1.44-1.44 1.44H4.56c-.795 0-1.44-.646-1.44-1.44V4.56c0-.795.645-1.44 1.44-1.44H9c.795 0 1.44.645 1.44 1.44v13.62zm10.44-6c0 .794-.645 1.44-1.44 1.44H15c-.795 0-1.44-.646-1.44-1.44V4.56c0-.795.646-1.44 1.44-1.44h4.44c.795 0 1.44.645 1.44 1.44v7.62z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 30px;
  height: 30px;
}

.sharePopup_shareButton_2YLXl_4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-shadow: 0px 1px 3px 0 #ddd;
          box-shadow: 0px 1px 3px 0 #ddd;
  border: solid thin transparent;
  padding: 10px 15px;
  border-radius: 3px;
  cursor: pointer;
  color: #394EFF
}

.sharePopup_shareButton_2YLXl_4:hover {
  background-color: #F6F7FF;
  border-color: #D0D4F2;
}