.ChatControls_controls_1ofeG9E {
  height: 38px;
  /* margin-top: 5px; */
  /* background-color: white; */
  /* border-top: solid thin #CCC; */
}

.ChatControls_btnWrapper_2IjRikL {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 24px;
  font-size: 12px;
  color: #888
}

.ChatControls_btnWrapper_2IjRikL.ChatControls_disabled_15INZ5I {
  /* background-color: red; */
}

.ChatControls_btnWrapper_2IjRikL.ChatControls_disabled_15INZ5I svg {
  fill: #cc0000;
}

.ChatControls_endButton_3SqIpTc {
  background-color: #cc0000;
  border-radius: 3px;
  padding: 2px 8px;
  color: white;
  font-size: 12px;
}