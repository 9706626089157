.roleItem_actions_2BPgYtB {
  /* margin-left: auto; */
  /* opacity: 0; */
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center
}

.roleItem_actions_2BPgYtB .roleItem_button_b4_GTq9 {
  padding: 5px;
  cursor: pointer;
  margin-left: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center
}

.roleItem_actions_2BPgYtB .roleItem_button_b4_GTq9:hover svg {
  fill: #2331A8;
}

.roleItem_actions_2BPgYtB .roleItem_button_b4_GTq9.roleItem_disabled_3HFYbNh {
  pointer-events: none;
  opacity: 0.5;
}

.roleItem_actions_2BPgYtB .roleItem_disabled_3HFYbNh {
  pointer-events: none;
  opacity: 0.5;
}

.roleItem_label_3uYa9G3 {
  margin-right: 10px;
  padding: 0 5px;
  border-radius: 3px;
  background-color: #f6f6f6;
  font-size: 12px;
  border: solid thin #ddd;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}