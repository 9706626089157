.SaveSearchModal_modalHeader_1ah54y2 {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.SaveSearchModal_cancelButton_3Kcb0jT {
  background-color: transparent !important;
  border: solid thin transparent !important;
  color: #394EFF !important
}

.SaveSearchModal_cancelButton_3Kcb0jT:hover {
  background-color: #F6F7FF !important;
}

.SaveSearchModal_applyButton_yYpi-SR {
  background-color: white !important;
  border: solid thin #D0D4F2 !important;
  color: #394EFF !important
}

.SaveSearchModal_applyButton_yYpi-SR:hover {
  background-color: #F6F7FF !important;
}